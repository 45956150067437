.aText {
    text-decoration: none;
    background-color: white;
    color: black;
    text-align: center;
}

.aText:hover {
  font-size: 50%;
}

.header {
  /*background-color: #ffffff;*/
  background: url("../cult_logo.jpg");
  background-size: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}